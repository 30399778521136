var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (!_vm.mounted)?_c('div',{staticClass:"mt-8"},[_vm._v("Chargement ...")]):_c('div',[_c('v-row',[(_vm.context.hasCodeFederal)?_c('v-col',{staticClass:"text-right"},[_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-btn',_vm._g({staticClass:"mx-2",attrs:{"fab":"","x-small":"","dark":"","color":"#1976D2"},on:{"click":function($event){$event.preventDefault();_vm.userModalIsOpen = true}}},on),[_c('v-icon',{attrs:{"dark":""}},[_vm._v("mdi-magnify")])],1)]}}],null,false,395745903)},[_c('span',[_vm._v("Sélectionner une personne dans la liste des membres")])]),_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-btn',_vm._g({staticClass:"mx-2",attrs:{"fab":"","x-small":"","dark":"","color":"#1976D2"},on:{"click":function($event){$event.preventDefault();return _vm.routeToAddUser.apply(null, arguments)}}},on),[_c('v-icon',{attrs:{"dark":""}},[_vm._v("mdi-plus")])],1)]}}],null,false,3576328717)},[_c('span',[_vm._v("Créer un membre si non-présent dans la liste des membres")])])],1):_vm._e()],1),_c('v-row',{key:'table_' + _vm.tableSalariesKey},[_c('v-simple-table',{staticStyle:{"width":"100%"},scopedSlots:_vm._u([{key:"default",fn:function(){return [_c('thead',[_c('tr',[_c('th',{staticClass:"text-left"}),_c('th',{staticClass:"text-left"},[_vm._v("Nom")]),_c('th',{staticClass:"text-left"},[_vm._v("Prénom")]),_c('th',{staticClass:"text-left"},[_vm._v("Mail")]),_c('th',{staticClass:"text-left"},[_vm._v("Tâche principale")]),_c('th',{staticClass:"text-left"},[_vm._v("Action")])])]),(_vm.usersSalaries.length)?_c('tbody',_vm._l((_vm.usersSalaries),function(item,index){return _c('tr',{key:index},[_c('td',[_c('Avatar',{attrs:{"userData":item.UTI_Utilisateur,"showAvatar":false,"size":"32"}})],1),_c('td',[_vm._v(_vm._s(item.UTI_Utilisateur.CT_Nom))]),_c('td',[_vm._v(_vm._s(item.UTI_Utilisateur.CT_Prenom))]),_c('td',[_vm._v(_vm._s(item.UTI_Utilisateur.CT_Email))]),_c('td',[_c('inputSelect',{attrs:{"disabled":!_vm.context.hasCodeFederal,"value":item.ID_MissionSalarie,"items":_vm.allTasks,"item_text":'NomMission',"item_value":'id'},on:{"onChange":function (missionID) { return _vm.updateSalarieMission(missionID, item); }}})],1),(_vm.context.hasCodeFederal)?_c('td',{staticClass:"text-right"},[_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-btn',_vm._g({staticClass:"mx-2",attrs:{"fab":"","x-small":"","dark":"","color":"red"},on:{"click":function($event){$event.preventDefault();return _vm.deleteUser(item)}}},on),[_c('v-icon',{attrs:{"dark":""}},[_vm._v("mdi-minus")])],1)]}}],null,true)},[_c('span',[_vm._v("Supprimer le salarié de la liste")])])],1):_vm._e()])}),0):_c('tbody',[_c('span',[_vm._v("aucun enregistrement.")])])]},proxy:true}])})],1),_c('ModalUsers',{key:'modal_' + _vm.tableSalariesKey,attrs:{"isOpen":_vm.userModalIsOpen,"context":{
      ID_Structure: _vm.context.ID_Structure,
      ID_Saison: _vm.selectedSaison.id,
      onlyCurrentStructure: true,
    }},on:{"onClose":function($event){_vm.userModalIsOpen = false},"onUserSelected":_vm.addUser}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }