<template>
  <v-dialog
    v-model="isOpen"
    @click:outside="close()"
    persistent
    max-width="800px"
  >
    <v-tabs value="slug" background-color="blue-dark" dark grow>
      <v-tabs-slider></v-tabs-slider>
      <v-tab>Membres</v-tab>
      <v-icon class="close-icon" @click="close">mdi-close</v-icon>
    </v-tabs>

    <div class="modal__content">
      <v-tabs-items>
        <v-card flat>
          <userList
            :context="context"
            :newUser="newUser"
            :headers="headers"
            actions="show|selectUser"
            simpleSearch
            @onUserSelected="userSelected"
          ></userList>
        </v-card>
      </v-tabs-items>
    </div>
  </v-dialog>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
import userList from "@/components/User/List/User__List.vue";

export default {
  components: { userList },

  data: () => ({
    membres: [],
    loading: true,
    headers: [
      { text: "Nom/Prénom", value: "FullName" },
      { text: "Actions", value: "actions" },
    ],
  }),

  computed: {
    ...mapGetters("structure", ["currentStructure", "getStructure"]),
  },
  methods: {
    ...mapActions("utilisateurs", ["getStructureMembre"]),

    userSelected(user) {
      this.$emit("onUserSelected", user);
    },
    close() {
      this.$emit("onClose");
    },
  },

  props: {
    isOpen: {
      type: Boolean,
      default: false,
    },

    context: {
      type: Object,
    },
    newUser: {
      type: Array,
    },
  },
};
</script>
