<template>
  <div v-if="!mounted">Chargement ...</div>
  <div v-else style="width: 100%">
    <ValidateForm @validated="updateEquivalentTempsPlein" :showFieldObl="false">
      <div class="mt-5 mb-5">
        <inputText
          type="number"
          step="0.1"
          v-model="SAL_EquivalentTempsPlein"
          label=" Nombre d'équivalents temps plein dans mon club/section de club"
          :disabled="!hasCodeFederal || disabled"
          rules="max:3"
          :width="60"
        >
        </inputText>
        <span class="caption font-italic"
          >(exemple: 0.5 pour un salarié à mi-temps)</span
        >
      </div>

      <div class="mt-5">
        <selectInput
          label="Si la structure a des salariés, quel est l'organisme de la formation professionnelle"
          :items="allCollecteur"
          v-model="currentCollecteur"
          item_text="NomCollecteur"
          item_value="id"
          @onChange="collecteurChange"
          :disabled="!hasCodeFederal || disabled"
        ></selectInput>
      </div>

      <v-btn
        :disabled="!hasCodeFederal || disabled"
        type="submit"
        class="btn btn-primary mt-5"
      >
        Mettre à jour
      </v-btn>
    </ValidateForm>

    <!-- <span class="subtitle-1">Liste des salariés</span> -->
    <div class="w-100">
      <v-toolbar flat>
        <v-toolbar-title class="subtitle-1"
          >Liste des salariés</v-toolbar-title
        >

        <v-spacer></v-spacer>
      </v-toolbar>
      <GestionSalaries
        :context="{
          hasCodeFederal,
          ID_Structure: structureID,
          ID_Saison: this.selectedSaison.id,
        }"
      />
    </div>
  </div>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
import {
  success_notification,
  error_notification,
} from "@/plugins/notifications";
import GestionSalaries from "@/components/Structure/GestionSalaries/Structure__GestionSalaries.vue";
import ValidateForm from "@/components/utils/form.vue";
import inputText from "@/components/utils/input.vue";
import selectInput from "@/components/utils/select.vue";

export default {
  components: { GestionSalaries, inputText, ValidateForm, selectInput },
  data: () => ({
    mounted: false,
    affiliation: null,
    SAL_EquivalentTempsPlein: "",
    allCollecteur: [],
    structure: null,
    currentCollecteur: null,
    collecteurId: null,
    structureID: null,
  }),

  async created() {
    this.$emit("updateTitle", "Gestion des salariés");
    await this.getListTypesDocument();
    await this.getListCollecteurFormationProfessionnelle();

    await this.setData();
    this.mounted = true;
    return;
  },

  computed: {
    ...mapGetters("basics", ["getList"]),
    ...mapGetters("structure", [
      "currentStructureId",
      "currentStructure",
      "hasCodeFederal",
    ]),
    ...mapGetters("saisons", ["selectedSaison", "currentSaison"]),

    disabled() {
      if (!this.affiliation) return true;
      return false;
    },
  },

  methods: {
    ...mapActions("basics", [
      "getListTypesDocument",
      "getListCollecteurFormationProfessionnelle",
    ]),
    ...mapActions("structure", [
      "getStructureAffiliation",
      "updateAffiliationETPStructure",
      "updateAffiliationCollecteurFormationPro",
    ]),

    async setData() {
      this.structureID = parseInt(this.$route.params.id);
      if (!this.structureID) this.structureID = this.currentStructureId;
      console.log("this.structureID *********", this.structureID);

      this.structure = { ...this.currentStructure };
      this.affiliation = await this.getStructureAffiliation({
        ID_Structure: this.structureID,
        ID_Saison: this.selectedSaison.id,
      });
      this.allCollecteur = this.getList.LIST_CollecteurFormationProfessionnelle;
      this.SAL_EquivalentTempsPlein = this.affiliation.SAL_EquivalentTempsPlein;

      if (this.affiliation.ID_CollecteurFormationProfessionnelle) {
        this.currentCollecteur = this.allCollecteur.find(
          (c) => c.id == this.affiliation.ID_CollecteurFormationProfessionnelle
        );
      }
    },

    async updateEquivalentTempsPlein() {
      try {
        await this.updateAffiliationETPStructure({
          ID_Structure: this.structureID,
          ID_Saison: this.selectedSaison.id,
          SAL_EquivalentTempsPlein: this.SAL_EquivalentTempsPlein,
        });
        await this.updateAffiliationCollecteurFormationPro({
          ID_Structure: this.structureID,
          ID_Saison: this.selectedSaison.id,
          ID_CollecteurFormationProfessionnelle: this.collecteurId?.id,
        });

        success_notification("Mis à jour");
      } catch (e) {
        error_notification("Oups, une erreur est survenue");
        console.log(e);
      }
    },

    async collecteurChange(collecteur_id) {
      this.collecteurId = this.getList.LIST_CollecteurFormationProfessionnelle.find(
        (col) => col.id == collecteur_id
      );
      // try {
      //   await this.updateAffiliationCollecteurFormationPro({
      //     ID_Structure: this.currentStructureId,
      //     ID_Saison: this.selectedSaison.id,
      //     ID_CollecteurFormationProfessionnelle: this.collecteurId.id,
      //   });
      //   success_notification("Organisme de formation mis à jour");
      // } catch (e) {
      //   error_notification("Oups, une erreur est survenue");
      //   console.log(e);
      // }
    },
  },
};
</script>
