<template>
  <div class="mt-8" v-if="!mounted">Chargement ...</div>
  <div v-else>
    <v-row>
      <!-- <v-col class="mt-6">
        <h6>Gestion des salariés</h6>
      </v-col> -->
      <v-col v-if="context.hasCodeFederal" class="text-right">
        <v-tooltip top>
          <template v-slot:activator="{ on }">
            <v-btn
              v-on="on"
              class="mx-2"
              fab
              x-small
              dark
              color="#1976D2"
              @click.prevent="userModalIsOpen = true"
            >
              <!-- <v-icon dark>mdi-plus</v-icon> -->
              <v-icon dark>mdi-magnify</v-icon>
            </v-btn>
          </template>

          <span>Sélectionner une personne dans la liste des membres</span>
        </v-tooltip>
        <v-tooltip top>
          <template v-slot:activator="{ on }">
            <v-btn
              v-on="on"
              class="mx-2"
              fab
              x-small
              dark
              color="#1976D2"
              @click.prevent="routeToAddUser"
            >
              <!-- <v-icon dark>mdi-plus</v-icon> -->
              <v-icon dark>mdi-plus</v-icon>
            </v-btn>
          </template>

          <span>Créer un membre si non-présent dans la liste des membres</span>
        </v-tooltip>
      </v-col>
    </v-row>

    <v-row :key="'table_' + tableSalariesKey">
      <v-simple-table style="width: 100%">
        <template v-slot:default>
          <thead>
            <tr>
              <th class="text-left"></th>
              <th class="text-left">Nom</th>
              <th class="text-left">Prénom</th>
              <th class="text-left">Mail</th>
              <th class="text-left">Tâche principale</th>
              <th class="text-left">Action</th>
            </tr>
          </thead>
          <tbody v-if="usersSalaries.length">
            <tr v-for="(item, index) in usersSalaries" :key="index">
              <td>
                <Avatar
                  :userData="item.UTI_Utilisateur"
                  :showAvatar="false"
                  size="32"
                ></Avatar>
              </td>
              <td>{{ item.UTI_Utilisateur.CT_Nom }}</td>
              <td>{{ item.UTI_Utilisateur.CT_Prenom }}</td>
              <td>{{ item.UTI_Utilisateur.CT_Email }}</td>
              <td>
                <inputSelect
                  :disabled="!context.hasCodeFederal"
                  @onChange="
                    (missionID) => updateSalarieMission(missionID, item)
                  "
                  :value="item.ID_MissionSalarie"
                  :items="allTasks"
                  :item_text="'NomMission'"
                  :item_value="'id'"
                ></inputSelect>
              </td>
              <td class="text-right" v-if="context.hasCodeFederal">
                <v-tooltip top>
                  <template v-slot:activator="{ on }">
                    <v-btn
                      v-on="on"
                      class="mx-2"
                      fab
                      x-small
                      dark
                      color="red"
                      @click.prevent="deleteUser(item)"
                    >
                      <v-icon dark>mdi-minus</v-icon>
                    </v-btn>
                  </template>

                  <span>Supprimer le salarié de la liste</span>
                </v-tooltip>
              </td>
            </tr>
          </tbody>
          <tbody v-else>
            <span>aucun enregistrement.</span>
          </tbody>
        </template>
      </v-simple-table>
    </v-row>

    <ModalUsers
      :isOpen="userModalIsOpen"
      :context="{
        ID_Structure: context.ID_Structure,
        ID_Saison: selectedSaison.id,
        onlyCurrentStructure: true,
      }"
      @onClose="userModalIsOpen = false"
      @onUserSelected="addUser"
      :key="'modal_' + tableSalariesKey"
    ></ModalUsers>
  </div>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import inputSelect from "@/components/utils/select";
import ModalUsers from "@/components/utils/modals/users";
import {
  success_notification,
  error_notification,
} from "@/plugins/notifications";
import Avatar from "@/components/Avatar.vue";

export default {
  components: { ModalUsers, Avatar, inputSelect },
  data: () => ({
    mounted: false,
    structure: null,
    usersSalaries: [],
    allTasks: [],
    userModalIsOpen: false,
    utilisateurs: [],
    tableSalariesKey: 0,
  }),

  async created() {
    await this.setData();
    this.allTasks = await this.getSalarieTasks();
    this.mounted = true;
  },

  computed: {
    ...mapGetters("structure", ["getStructure"]),
    ...mapGetters("saisons", ["selectedSaison"]),
  },

  methods: {
    ...mapActions("structure", [
      "updateStructreSalarieUser",
      "getSalarieTasks",
      "updateSalarieTask",
    ]),
    ...mapActions("utilisateurs", [
      "getUtlisateursInStructure",
      "updateUser",
      "getListDirigeants",
      "getUtilisateur",
    ]),

    async setData() {
      this.structure = {
        ...this.getStructure({ ID_Saison: this.context.ID_Saison }),
      };
      // this.utilisateurs = await this.getUtlisateursInStructure({
      //   ID_Structure: this.context.ID_Structure,
      // });

      let payload = {};
      let arrayClause = {
        EST_Actif: { _eq: true },
      };
      arrayClause.ID_Structure = { _eq: this.context.ID_Structure };
      payload.where = { ...arrayClause };

      this.utilisateurs = await this.getListDirigeants(payload);

      this.usersSalaries = [
        ...this.utilisateurs.filter((user) => user.EST_Salarier),
      ];
      console.log(this.usersSalaries);
      this.updateSalarieKey();
    },

    async addUser(user) {
      try {
        await this.updateStructreSalarieUser({
          ID_Structure: this.context.ID_Structure,
          user: user,
          EST_Salarier: true,
        });
        success_notification(
          "L'utilisateur " +
            user.UTI_Utilisateur.CT_Prenom +
            " " +
            user.UTI_Utilisateur.CT_Nom +
            " a été ajouté dans la liste des salariés de la structure"
        );
        this.setData();
      } catch (e) {
        error_notification("Oups, une erreur est survenue");
      }
      this.userModalIsOpen = false;
    },

    async deleteUser(user) {
      try {
        await this.updateStructreSalarieUser({
          ID_Structure: this.context.ID_Structure,
          user: user,
          EST_Salarier: false,
        });
        await this.updateSalarieTask({
          ID_Utilisateur: user.ID_Utilisateur,
          ID_Mission: null,
        });
        success_notification(
          user.UTI_Utilisateur.CT_Prenom +
            " " +
            user.UTI_Utilisateur.CT_Nom +
            " n'est plus dans la liste des salariés de la structure"
        );
        this.setData();
      } catch (e) {
        error_notification("Oups, une erreur est survenue");
        this.userModalIsOpen = false;
      }
    },

    capitalizeFirstLetter(string) {
      let str = string.split(" ");
      for (var i = 0, x = str.length; i < x; i++) {
        str[i] = str[i][0].toUpperCase() + str[i].substr(1);
      }
      return str.join(" ");
    },

    async updateSalarieMission(missionID, salarie) {
      try {
        await this.updateSalarieTask({
          ID_Utilisateur: salarie.ID_Utilisateur,
          ID_Mission: missionID,
        });
      } catch (error) {
        console.log(error);
      }
    },

    routeToAddUser() {
      window.open(`${process.env.VUE_APP_FRONT_NEXT_URL}/gestion-structure/utilisateurs/nouvel?structureId=${this.context.ID_Structure}&appMode=${localStorage.getItem("ffme_user_mode")}`, '_blank');
    },

    updateSalarieKey() {
      this.tableSalariesKey++;
    },
  },

  props: {
    context: {
      type: Object,
    },
  },
};
</script>
